@import './../../_theme.scss';
@import './../../_mixins.scss';

.LinkCard {
  @include card();
  background-color: $color-background-light-primary;
  color: $color-text-primary-on-light;
  box-shadow: none;
  box-sizing: border-box;
  height: 100%;
  padding: $spacing-lg;
  display: flex;
  border: 1px solid $color-brand-primary;

  &:hover {
    transform: none;
  }

  &__Link {
    text-decoration: none;
    height: 100%;
    width: 100%;
    display: flex !important;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  &__Content {
    @include text-body-sm();
    color: $color-brand-primary;
    margin-right: $spacing-sm;
  }

  &__Icon {
    fill: $color-brand-primary;
  }
}
