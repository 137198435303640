@import './../../_theme.scss';
@import './../../_mixins.scss';

.PageLayoutTextBlock {
  padding: $layout-lg 0 0;

  &__Title {
    @include text-heading-sm()
    color: $color-text-tertiary-on-light;
    padding: 0 0 $spacing-md 0;
  }

  &__Item {
    list-style: none;
    margin-bottom: $spacing-xs;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
