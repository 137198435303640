@import './../../_theme.scss';
@import './../../_mixins.scss';

.HighlightBadge {
  @include text-detail-legend();
  position: absolute;
  top: $spacing-sm;
  right: 0;
  border-radius: $border-radius-subtle 0 0 $border-radius-subtle;
  display: block;
  padding: $spacing-xs $spacing-sm;
  background-color: $color-brand-secondary;
  color: $color-text-primary-on-dark;
  box-shadow: $elevation-medium;
}
