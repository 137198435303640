@import './../../_theme.scss';
@import './../../_mixins.scss';

.PageLayoutLinksBlock {
  padding: 64px 0 0;

  &__Title {
    @include text-heading-sm()
    color: $color-text-tertiary-on-light;
    padding: 0 0 $spacing-md 0;
  }

  &__Item {
    list-style: none;
    margin-bottom: $spacing-xs;
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 580px;
    justify-content: space-between;
    margin: $spacing-xl 0;

    &:first-child {
      margin-top: $spacing-md;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &__Label {
    }

    &__Value {
      color: $color-interactive-on-light;

      &__Link {
        transition: all $transition-normal cubic-bezier(.25,.8,.25,1);
        color: $color-interactive-on-light;
        text-decoration: none;

        &:hover {
          color: darken($color-interactive-on-light, 20%);
        }

        &:after {
          content: '>';
          padding-left: $spacing-xs;
          font-weight: $font-weight-medium;
        }
      }
    }
  }

  @media (max-width: $breakpoint-md) {
    &__Item {
      flex-direction: column;

      &__Value {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
