@import './../../_theme.scss';
@import './../../_mixins.scss';

.OutstandingCard {
  @include card();
  height: 400px;
  display: block;
  text-decoration: none;
  background-color: transparent;

  &__Background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: block;

    &__Image {
      width: auto;
      height: 100%;
      position: absolute;
      filter: saturate(1.2);
      opacity: 0;
      top: 50%;
      left: 50%;
      transform-origin: left top;
      transform: perspective(500px) translate3d(-50%, -50%, 0);
      transition: all $transition-slow cubic-bezier(.25,.8,.25,1);
    }

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: linear-gradient(rgba(0,0,0,0) 50%, rgba(0,0,0,0.4) 100%), radial-gradient(rgba(0,0,0,0) 50%, rgba(0,0,0,0.3));
    }

    &.lazy-load-image-loaded {
      .OutstandingCard__Background__Image {
        opacity: 1;
      }
    }
  }

  &__Content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    text-align: center;

    &__Position {
      @include text-detail-rank();
      transition: all $transition-slow cubic-bezier(.25,.8,.25,1);
      color: $color-text-primary-on-light;
      width: $spacing-xxxl;
      height: $spacing-xxxl;
      display: inline-block;
      position: relative;
      text-align: center;
      line-height: $spacing-xxxl;
      z-index: 1;
      margin: 0 auto;

      &:before {
        content: '';
        transition: all $transition-slow cubic-bezier(.25,.8,.25,1);
        width: 100%;
        height: 100%;
        background-color: $color-background-light-primary;
        position: absolute;
        border-radius: $border-radius-blunt;
        transform: rotate(45deg);
        top: 0;
        left: 0;
        z-index: -1;
        box-shadow: $elevation-high;
      }
    }

    &__TitleWrapper {
      height: $spacing-xxxl;
      display: flex;
      place-items: center;
      transition: all $transition-slow cubic-bezier(.25,.8,.25,1);
      margin: $spacing-xl $spacing-lg $spacing-xxxl $spacing-lg;
      text-align: center;
    }

    &__Title {
      @include text-detail-accent();
      text-align: center;
      width: 100%;
      color: $color-text-primary-on-dark;
      text-shadow: $elevation-high;
    }
  }

  &:hover & {
    &__Background {
      &__Image {
        filter: saturate(1.8);
        transform: perspective(500px) translate3d(-50%, -50%, 50px);
      }
    }

    &__Content {
      &__Position {
        transform: translateY($spacing-md * -1);

        &:before {
          box-shadow: $elevation-higher;
        }
      }

      &__TitleWrapper {
        transform: translateY($spacing-xxs * -1);
      }
    }
  }
}
