@import './../../_theme.scss';
@import './../../_mixins.scss';

.Navigation {
  width: 100%;
  height: $layout-md + $spacing-lg + $spacing-lg;
  background-color: $color-background-light-primary;
  box-shadow: $elevation-highest;
  position: fixed;
  z-index: 10;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  &__Brand {
    @include text-body-sm;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    font-weight: $font-weight-medium;
    letter-spacing: -0.12px;
    line-height: 24px;
    color: $color-brand-primary;

    &__Icon {
      padding: $spacing-sm;
      background-color: $color-brand-primary;
      margin-right: $spacing-sm;
      fill: $color-text-primary-on-dark;
      border-radius: $border-radius-blunt;
      color: $color-text-primary-on-dark;
      width: $spacing-xl;
      height: $spacing-xl;
    }
  }

  &__Menu {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }

  &__Item {
    @include text-body-sm;
    text-decoration: none;
    color: $color-text-primary-on-light;
    margin-left: $spacing-xxl;
    transition: all $transition-slow cubic-bezier(.25,.8,.25,1);

    &:hover {
      color: $color-brand-primary;
    }

    &--Selected {
      color: $color-brand-primary;
    }
  }

  &__Icon {
    display: none;
    width: $spacing-xl;
    height: $spacing-xl;
  }
  
  @media (max-width: $breakpoint-xl + $layout-xs + $layout-xs) {
    &__Brand {
      padding-left: $layout-xs;
    }

    &__Menu {
      padding-right: $layout-xs;
    }
  }

  @media (max-width: $breakpoint-md) {
    height: $layout-md + $spacing-sm + $spacing-sm;
    box-shadow: $elevation-highest;
    box-shadow: 0 -20px 32px rgba(0,0,0,0.08);
    bottom: 0;
    top: auto;

    .Page__Wrapper {
      padding: 0;
    }

    &__Brand {
      display: none;
    }

    &__Menu {
      width: 100%;
      padding: 0;
      justify-content: space-around;
      position: absolute;
      bottom: $spacing-xs;
    }

    &__Item {
      margin: 0;
      display: flex;
      flex-direction: column-reverse;
    }

    &__Text {
      text-align: center;
      font-size: $font-scale-10;
      line-height: $line-height-10;
      color: $color-text-primary-on-light;
    }

    &__Icon {
      display: block;
      width: $spacing-lg;
      height: $spacing-lg;
      padding: $spacing-sm $spacing-sm $spacing-xs $spacing-sm;
      fill: $color-text-primary-on-light;
    }
  }
}
