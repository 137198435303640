@import './../../_theme.scss';
@import './../../_mixins.scss';

.PageLayoutHero {
  width: 100%;
  overflow: hidden;
  font-size: 0;
  letter-spacing: 0;
  line-height: 0;
  position: relative;
  margin-top: $layout-xxl;

  &__Background {
    width: 100%;
    height: auto;
    filter: saturate(1.2);
    padding-bottom: 9/16 * 100%;
    border-radius: $border-radius-blunt;
    overflow: hidden;
    position: relative;
    display: block;

    &__Image {
      width: auto;
      height: 100%;
      position: absolute;
      filter: saturate(1.2);
      opacity: 0;
      top: 50%;
      left: 50%;
      transform-origin: left top;
      transform: perspective(500px) translate3d(-50%, -50%, 0);
      transition: all $transition-slow cubic-bezier(.25,.8,.25,1);
    }

    &.lazy-load-image-loaded {
      .PageLayoutHero__Background__Image {
        opacity: 1;
      }
    }
  }

  &__Content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__Title {
    @include text-display();
    color: $color-text-primary-on-dark;
    padding-top: $spacing-sm;
    padding-bottom: $spacing-sm;
    text-shadow: 0 0 50px rgba(0,0,0,1), 0 4px 6px rgba(0,0,0,0.5);
  }

  &__Subtitle {
    @include text-detail-accent();
    color: $color-text-primary-on-dark;
    text-shadow: 0 0 20px rgba(0,0,0,1), 0 2px 3px rgba(0,0,0,0.5);
  }

  @media (max-width: $breakpoint-xl + $layout-xs + $layout-xs) {
    width: auto;
    padding: 0 24px;
    // margin-top: 0;
    // border-radius: 0;
  }

  @media (max-width: $breakpoint-md) {
    padding: 0;
    margin-top: 0;

    &__Background {
      border-radius: 0;
    }
  }
}
