@import '../../_theme.scss';
@import '../../_mixins.scss';

.FiltersList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin: $spacing-lg auto $spacing-xl;
  max-width: $breakpoint-xxl;

  &__Item {
    @include text-detail-legend();
    padding: $spacing-xxs $spacing-sm;
    margin: 0 $spacing-xs $spacing-xs 0;
    display: inline-block;
    cursor: pointer;
    transition: all $transition-slow cubic-bezier(.25,.8,.25,1);
    white-space: nowrap;
    border-radius: $border-radius-normal;
    border: 1px $color-brand-primary solid;
    color: $color-brand-primary;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }

    &__Active {
      color: $color-text-primary-on-dark;
      background-color: $color-brand-primary;
      pointer-events: none;
      opacity: 1;
    }

    &--Clear {
      color: $color-text-primary-on-light;
      border-color: $color-text-primary-on-light;

      &__Hidden {
        display: none;
      }
    }
  }
}
