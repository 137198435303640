@import './../../_theme.scss';
@import './../../_mixins.scss';

.CardsSlider {
  margin-bottom: $layout-xl;
  width: 100%;

  &__Title {
    @include text-heading-md();
    color: $color-text-secondary-on-light;
    text-align: center;
    border-bottom: $border-width-hairline solid $color-border-separator-on-light;
    margin-bottom: $spacing-xxl;
    padding-bottom: $spacing-xl;
    position: relative;

    &:after {
      content: '';
      width: $spacing-xxxl;
      height: $spacing-xxxs;
      position: absolute;
      bottom: -1px;
      left: 50%;
      margin-left: $spacing-xxxl / 2 * -1;
      background-color: $color-brand-secondary;
    }
  }

  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: flex;
  }

  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden
  }

  .slick-slider { 
    box-sizing: border-box;
    user-select: none;
    padding-bottom: $layout-md;

    .slick-list,
    .slick-track {
      transform: translate3d(0,0,0);
    }

    .slick-list {
      overflow: hidden;
      width: 100%;

      &:focus {
        outline:0
      }

      &.dragging {
        cursor: pointer;
        cursor: hand;
      }
    }

    .slick-track {
      top: 0;
      left: 0;

      &:after,
      &:before {
        display: table;
        content: '';
      }

      &:after {
        clear: both;
      }
    }

    .slick-slide {
      display: none;
      float: left;
      height: 100%;
      min-height: 1px;

      > div {
        height: calc(100% - 24px);
        padding: $spacing-xs $spacing-md $spacing-md $spacing-md;
      }
    }

    .slick-arrow {
      transition: all $transition-fast linear;
      display: inline-block;
      position: absolute;
      width: $layout-lg;
      height: $layout-lg;
      top: 50%;
      margin-top: $layout-lg * -1;
      z-index: 1;
      cursor: pointer;
      opacity: 1;
      background-color: white;
      border-radius: $border-radius-circle;
      box-shadow: $elevation-high;
      box-sizing: border-box;
      padding: $spacing-md;

      > * {
        transition: all $transition-slow cubic-bezier(.25,.8,.25,1);
        fill: $color-text-tertiary-on-light;
        opacity: 1;
      }

      &.slick-next {
        right: 0;
        margin-right: $layout-lg * -0.25;
      }

      &.slick-prev {
        left: 0;
        margin-left: $layout-lg * -0.25;
      }

      &:hover {
        box-shadow: $elevation-higher;

        > * {
          fill: $color-brand-secondary;
          opacity: 1;
        }
      }
    }

    .slick-dots {
      width: 100%;
      height: $layout-sm;
      overflow: hidden;
      position: absolute;
      bottom: 0;
      font-size: 0;
      display: flex !important;
      justify-content: center;
      flex-direction: row;

      li {
        list-style: none;
        margin: 0 $spacing-xs;

        button {
          padding: 0;
          margin: 0;
          border: none;
          background: none;
          height: $layout-sm;
          width: $layout-sm;
          position: relative;
          font-size: 0;
          outline: none;
          cursor: pointer;

          &:after {
            content: '';
            transition: all $transition-normal cubic-bezier(.25,.8,.25,1);
            width: $spacing-sm;
            height: $spacing-sm;
            border-radius: $border-radius-circle;
            background-color: $color-text-tertiary-on-light;
            opacity: 0.3;
            display: block;
            position: absolute;
            top: ($layout-sm / 2) - ($spacing-sm / 2);
            left: $layout-sm / 2 - ($spacing-sm / 2);
          }

          &:hover {
            &:after {
              background-color: $color-brand-secondary;
              opacity: 1;
            }
          }
        }

        &.slick-active {
          button {
            outline: none;

            &:after {
              background-color: $color-brand-secondary;
              opacity: 1;
            }
          }
        }
      }
    }

    .slick-slide,
    .slick-list,
    .slick-track {
      transform: translate3d(0,0,0);
      transform: translateZ(0);
      perspective: 1000px;
      backface-visibility: hidden;
    }

    &.slick-initialized {
      .slick-slide {
        display: block;
      }
    }
  }
}
