@import './../../_theme.scss';
@import './../../_mixins.scss';

.MapMarker {
  border-radius: $border-radius-circle;
  width: $spacing-xl;
  height: $spacing-xl;
  background-color: $color-brand-secondary;
  box-shadow: $elevation-high;
  display: block;

  &__Title {
    @include text-detail-legend();
    width: $layout-xxl;
    height: $layout-xxl;
    position: absolute;
    top: $layout-xxl * -1;
    left: 50%;
    margin-top: $layout-xxl * -0.5;
    margin-left: $layout-xxl * -0.5;
    color: $color-text-primary-on-dark;
    background-color: $color-brand-secondary;
    box-sizing: border-box;
    text-align: center;
    padding: $spacing-md;
    border-radius: $border-radius-circle;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: $elevation-higher;

    &:after {
      content: '';
      width: $spacing-xxxs;
      height: $layout-xl;
      display: block;
      position: absolute;
      bottom: $layout-xl * -1;
      left: 50%;
      margin-left: $spacing-xxxs * -0.5;
      background-color: $color-brand-secondary;
    }
  }

  @media (max-width: $breakpoint-md) {
    &__Title {
      width: $layout-xl + $layout-sm;
      height: $layout-xl + $layout-sm;
      margin-top: ($layout-xl + $layout-sm) * -0.5;
      margin-left: ($layout-xl + $layout-sm) * -0.5;
      top: ($layout-xl + $layout-sm) * -1;
      line-height: 16px;
      padding: $spacing-xs;

      &:after {
        height: $layout-lg;
        bottom: $layout-lg * -1;
      }
    }
  }
}
