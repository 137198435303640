@import './../../_theme.scss';
@import './../../_mixins.scss';

.TaxonomiesListItem {
  transition: all $transition-slow cubic-bezier(.25,.8,.25,1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;
  padding: $spacing-sm $spacing-md $spacing-sm $spacing-md;
  position: relative;
  border-radius: $border-radius-normal;
  background-color: $color-brand-primary;
  color: $color-text-primary-on-dark;
  text-decoration: none;
  margin-right: $spacing-sm;
  margin-bottom: $spacing-sm;

  &__Icon {
    background-color: transparent !important;
    width: $layout-sm;
    height: $layout-sm;
    margin-right: $spacing-md;
  }

  &__Title {
    @include text-body-xs();
    font-weight: $font-weight-medium;
    letter-spacing: $letter-space-close;
  }

  &:hover {
    box-shadow: $elevation-high;
    transform: translateY($spacing-xxs * -1);
    filter: brightness(1.05);
  }

  @media (max-width: $breakpoint-md) {
    padding: $spacing-xs $spacing-sm;

    &__Icon {
      margin-right: $spacing-xs;
    }
  }
}
