@import './../../_theme.scss';
@import './../../_mixins.scss';

.SpotCard {
  @include card();

  &__ImageWrapper {
    width: 100%;
    height: auto;
    padding-bottom: 75%;
    position: relative;
    display: block;

    &.lazy-load-image-loaded {
      .SpotCard__Image {
        opacity: 1;
      }
    }
  }

  &__Image {
    width: 100%;
    height: 100%;
    position: absolute;
    filter: saturate(1.2);
    opacity: 0;
    transition: all $transition-slow cubic-bezier(.25,.8,.25,1);
  }

  &__Link {
    text-decoration: none;
  }

  &__Title {
    @include text-body-sm();
    color: $color-text-primary-on-light;
    margin: $spacing-md $spacing-lg $spacing-md $spacing-md;
    line-height: 24px;
    height: 50px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &__Subtitle {
    @include text-detail-legend();
    color: $color-text-tertiary-on-light;
    padding: 0 $spacing-lg $spacing-md $spacing-md;
  }

  &:hover & {
    &__Image {
      filter: saturate(1.8);
    }
  }
}
