@import './../../_theme.scss';
@import './../../_mixins.scss';

.Header {
  display: none;
  width: 100%;
  height: $layout-md + $spacing-xs + $spacing-xs;
  background-color: $color-background-light-primary;
  z-index: 10;
  left: 0;
  top: 0;
  display: none;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  box-shadow: $elevation-highest;

  &__Content {
    width: auto;
    display: grid;
    grid-template-columns: $spacing-xxxl 1fr $spacing-xxxl;
    height: 100%;
    padding-left: $layout-xs;
    padding-right: $layout-xs;
    justify-content: space-between;
    align-items: center;
    justify-items: center;
  }

  &__Icon {
    justify-self: start;
    width: $spacing-lg;
    height: $spacing-lg;
    fill: black;
  }

  &__Title {
    @include text-detail-legend();
    color: $color-text-primary-on-light;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }

  @media (max-width: $breakpoint-md) {
    display: block;
  }
}
