@import './../../_theme.scss';
@import './../../_mixins.scss';

.PageLayoutTitle {
  padding: 0;
  
  &__Title {
    @include text-heading-lg()
    padding: $layout-xxxl 0 $layout-xs 0;
    overflow: hidden;
    width: 75%;
  }

  @media (max-width: $breakpoint-md) {
    &__Title {
      margin-top: $spacing-lg;
      padding: $layout-xl 0 0 0;
      width: 100%;
    }
  }
}
