@import '../../_theme.scss';

.ItemsGrid {
  display: grid;
  justify-content: center;
  margin: 0 auto;
  grid-column-gap: $layout-sm;
  grid-row-gap: $layout-md;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-auto-rows: auto;
}
