@import './../../_theme.scss';
@import './../../_mixins.scss';

.PageLayoutMap {
  border-radius: $border-radius-blunt;
  line-height: 0;
  font-size: 0;
  overflow: hidden;
  position: relative;
  box-shadow: 0 0 0 1px rgba(0,0,0,0.05);
  min-height: 300px;

  &__Canvas {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    .mapboxgl-canvas {
      &:focus {
        outline: none;
      }
    }
  }
}
