@import './../../_theme.scss';
@import './../../_mixins.scss';

.TaxonomiesList {
  padding: $layout-xxl 0 0;
  margin: 0;

  &__Title {
    @include text-heading-sm()
    color: $color-text-tertiary-on-light;
    padding: 0 0 $spacing-md 0;
  }

  &__Wrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  @media (max-width: $breakpoint-md) {
    padding: $layout-lg 0 0;
  }
}
